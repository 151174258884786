<template>
    <v-container>
        <v-row justify="center" class="py-5">
            <h1 class="display-1 font-weight-light">Link Account User</h1>
        </v-row>
        <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <v-card tile elevation="4" class="pa-5" id="create-account-form">
                        <v-form v-model="accountForm" @submit="linkAccountUser" onSubmit="return false;" @keyup.enter.native="linkAccountUser">
                            <v-row justify="center" class="pb-2">
                                <p class="title font-weight-light">Account Information</p>
                            </v-row>
                            <v-row class="px-5">
                                <v-card-text>Account name: <strong>{{accountName}}</strong></v-card-text>
                                <v-card-text>Account type: {{accountType}}</v-card-text>
                            </v-row>
                            <v-row justify="center" class="pb-2">
                                <p class="title font-weight-light">User Information</p>
                            </v-row>
                            <v-row justify="center" class="px-5" v-if="!userId">
                                <v-text-field
                                v-model = "email"
                                label="Email address"
                                :rules="emailRules"
                                outlined
                                required>
                                </v-text-field>
                            </v-row>
                            <v-row justify="start" class="px-5" v-if="!userId">
                                <v-list v-if="list && list.length > 0">
                                    <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2">
                                        <!-- <span>{{ JSON.stringify(item) }}</span> -->
                                        <UserListItem :attr="item"></UserListItem> <v-btn @click="selectUser(item.id, item.content.name)">Select</v-btn>
                                    </v-list-item>
                                </v-list>
                                <v-card-text v-if="email && list && list.length === 0">No records.</v-card-text>
                            </v-row>
                            <v-row class="px-5" v-if="userId">
                                <v-card-text>User name: <strong>{{name}}</strong></v-card-text>
                                <v-card-text>User email: {{email}}</v-card-text>
                                <v-btn @click="resetUser">Clear</v-btn>
                            </v-row>
                            <v-row justify="center" class="pt-3 pb-5">
                                <v-btn elevation="4" class="blue white--text" @click="linkAccountUser" :disabled="!accountForm">Continue</v-btn>
                            </v-row>
                        </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { /* mapGetters, */ mapState } from 'vuex';
import { /* isValidName, */ isValidEmail /* compact */ } from '@/sdk/input';
import UserListItem from '@/components/UserListItem.vue';

export default {
    components: {
        UserListItem,
    },
    data() {
        return {
            accountId: null,
            accountName: null,
            accountType: null,
            userId: null,
            email: null,
            list: [], // list of users matching the email address; ideally it would only be one user but there may be multiple matches
            error: null,
            emailRules: [
                (v) => isValidEmail(v) || 'Enter an email address to receive account notifications',
            ],
        };
    },

    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
        }),
        // ...mapGetters({
        // currentAccount: 'account',
        // enterpriseAccountList: 'enterpriseAccountList',
        // }),
        // compactEnterpriseAccountNames() {
        // return this.enterpriseAccountList.map((item) => compact(item.name));
        // },
    },

    watch: {
        isReady(value, oldValue) {
            console.log('subscribeEnterprise: isReady changed from %o to %o', oldValue, value);
            // only call init again if ready changed from false to true after mounted()
            if (value && !oldValue) {
                this.init();
            }
        },
    },

    methods: {
        async init() {
            console.log(`accountid ${this.accountId}`);
            if (this.accountId) {
                // see reportAccountById in ReportView
                const report = await this.$client.data.report({ id: 'accountById', accountId: this.accountId });
                // console.log(`arport ${JSON.stringify(report.content.content)}`);
                if (!report) {
                    this.error = 'Request failed';
                    return;
                }
                this.accountName = report.content.content.name;
                this.accountType = report.content.content.type;
            }
        },
        async linkAccountUser() {
            this.$store.commit('loading', { linkAccountUser: true });
            if (this.userId) {
                // a user is already selected , link it
                const { isLinked } = await this.$client.account.linkUser({ accountId: this.accountId, userId: this.userId });
                if (isLinked) {
                    // redirect to account view
                    this.$router.push({ path: '/report/view', query: { id: 'account', accountId: this.accountId } });
                } else {
                    console.error('failed to link account');
                }
                return;
            }
            if (this.email) {
                // see reportAccountById in ReportView
                const report = await this.$client.data.report({ id: 'user-list-by-email', email: this.email });
                console.log(`arport ${JSON.stringify(report)}`);
                if (!report) {
                    this.error = 'Request failed';
                    // return;
                }
                this.list = report.data;
                // this.accountName = report.content.content.name;
                // this.accountType = report.content.content.type;
            }
        },
        selectUser(id, name) {
            this.userId = id;
            this.name = name;
            console.log(`selected user ${id} with name ${name}`);
        },
        resetUser() {
            this.userId = null;
            this.name = null;
            this.email = null;
            this.list = [];
        },
    },
    created() {
        this.accountId = this.$route.query.accountId;
    },
    mounted() {
        console.log('mounted');
        if (this.isReady) {
            this.init();
        }
    },
};
</script>
